<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-03-25 11:39:18
-->
<template>
	<div class="login">
		<div class="login_form animate__animated animate__zoomInDown">
			<img src="@/assets/images/login/logo.png" class="login_logo">
			<img src="@/assets/images/login/welcome.png" class="login_welcome">
			<div class="login_item">
				<div class="login_label">
					<img src="@/assets/images/login/user.png" class="label_icon">
					<span class="label_text">账号</span>
				</div>
				<input v-model="loginForm.username" type="text" class="login_ipt" placeholder="请输入手机号" />
			</div>
			<div class="login_item">
				<div class="login_label">
					<img src="@/assets/images/login/pwd.png" class="label_icon">
					<span class="label_text">密码</span>
				</div>
				<div class="login-item">
					<div class="left">
						<input v-model="loginForm.password"
						class="login_ipt" 
						:type="isShowPwd ? 'password':'text'"
						placeholder="请输入密码" />
						<i v-if="isShowPwd" @click="showPwd" class="iconfont icon-buxianshimima"></i>
						<i v-else @click="showPwd" class="iconfont icon-xianshimima"></i>
					</div>
				</div>
			</div>
			<div class="login_remeber">
				<el-checkbox @change="remeberMe" v-model="loginForm.remeber">记住密码</el-checkbox>
			</div>
			<div @click="login" class="login_btn">
				登录
			</div>
		</div>
		<div class="police_record">
			<span>Copyright © 杭州予睿教育科技有限公司</span>
			<span class="margin_16">|</span>
			<span @click="linkTo('icp')" class="police_record_link">浙ICP备2022012975号</span>
			<span class="margin_16">|</span>
			<img src="@/assets/images/login/police.png">
			<span @click="linkTo('police')" class="police_record_link">浙公网安备 33010602012348</span>
			<span class="margin_16">|</span>
			<span @click="linkTo('userAgreement')" class="police_record_link">用户协议</span>
			<span class="margin_16">|</span>
			<span @click="linkTo('PrivacyAgreement')" class="police_record_link">隐私协议</span>
		</div>
	</div>
</template>
<script>
	import storage from 'store'
	import {
		mapActions
	} from 'vuex'
	import commonFn from "@/utils/common.js";
	export default {
		data() {
			return {
				loginForm: {
					username: "",
					passwor: "",
					remeber: false,
				},
				isShowPwd:true,
			}
		},
		created() {
			if (storage.get('REMEMBER_ME')) {
				this.loginForm.remeber = storage.get('REMEMBER_ME')
				if (this.loginForm.remeber) {
					this.loginForm.username = storage.get('REMEMBER_NAME')
					this.loginForm.password = storage.get('REMEMBER_PWD')
				}
			}
		},
		methods: {
			...mapActions(['Login', 'GetUserInfo']),
			remeberMe() {
				if (this.loginForm.remeber) {
					if (this.loginForm.username || this.loginForm.password) {
						storage.set('REMEMBER_NAME', this.loginForm.username, 7 * 24 * 60 * 60 * 1000)
						storage.set('REMEMBER_PWD', this.loginForm.password, 7 * 24 * 60 * 60 * 1000)
					}
				} else {
					if (storage.get('REMEMBER_NAME') || storage.get('REMEMBER_PWD')) {
						storage.remove('REMEMBER_NAME')
						storage.remove('REMEMBER_PWD')
					}
				}
				storage.set('REMEMBER_ME', this.loginForm.remeber, 7 * 24 * 60 * 60 * 1000)
			},
			showPwd() {
			this.isShowPwd = !this.isShowPwd;
			},
			linkTo(type) {
				let url = ""
				if(process.env.NODE_ENV === "production") {
					url = "https://www.nowyourise.com.cn"
				} else {
					url = "http://121.41.38.209:21000"
				}
				switch (type) {
					case "icp":
						window.open("https://beian.miit.gov.cn")
						break;
					case "police":
						window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602012348")
						break;
					case "PrivacyAgreement":
						window.open(url + "/#/PrivacyAgreement")
						break;
					case "userAgreement":
						window.open(url + "/#/useAgreement")
						break;
				}
			},
			login() {
				const {
					username,
					password
				} = this.loginForm
				if (!username) {
					this.$message.error('请输入手机号~');
					return
				} else if (!password) {
					this.$message.error('请输入密码~');
					return
				}
				this.Login(this.loginForm).then(async (res) => {
					console.log('登录成功了', res)
					await this.GetUserInfo()
					this.$router.push({
						path: '/dashboard'
					})
					this.remeberMe()
					setTimeout(() => {
						this.$notify({
							dangerouslyUseHTMLString: true,
							message: `<strong>尊敬的<span style="color:#5BA897;margin:0 4px;">${storage.get('ACCESS-NAME')}</span>${commonFn.timeFix()}, 欢迎来到予睿教育管理系统~</strong>`,
							duration: 4000
						});
					}, 1000);
				})
			}
		},
	}
</script>
<style lang="scss" scoped>
	@import "../../styles/index.scss";

	/deep/input::-webkit-input-placeholder {
		font-size: 20px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 56px;
		color: #CCCCCC !important;
	}

	/deep/input:focus {
		outline: none;
		border: 1px solid $theme_color !important;

	}

	/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: $theme_color;
		color: $theme_color;
		border-color: $theme_color;
	}

	/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: $theme_color;
	}

	/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
		color: $theme_color;
	}

	/deep/.el-checkbox__inner:hover {
		border-color: $theme_color;
	}
	.login-item {
          display: flex;
          align-items: center;
          width: 100%;
		  position:relative;
          .left {
            flex: 1;
            display: flex;
            align-items: center;
            .iconfont {
              font-size: 20px;
              cursor: pointer;
              color:#BDC6D0;
			  position:absolute;
			  right:20px;
			  top:50%;
			  transform: translateY(-50%)
            }
		  }
	}
	.login {
		width: 100%;
		height: 100vh;
		background-image: url("../../assets/images/login/bg.png");
		background-size: 100% 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		.login_form {
			width: 560px;
			height: 640px;
			background: #FFFFFF;
			border: 4px solid #FFFFFF;
			box-shadow: 0px 0px 40px #3FA58F;
			border-radius: 10px;
			padding: 60px 36px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.login_logo {
				width: 272px;
				height: 50px;
				margin: 0 auto 20px auto;
			}

			.login_welcome {
				width: 104px;
				height: 30px;
				margin: 0 auto;
			}

			.login_item {
				display: flex;
				flex-direction: column;
				margin-top: 32px;

				.login_label {
					display: flex;
					align-items: center;
					margin-bottom:10px;
					.label_icon {
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}

					.label_text {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 36px;
						color: #999999;
					}
				}

				.login_ipt {
					width: 488px;
					line-height: 56px;
					border: 1px solid #DDDDDD;
					border-radius: 28px;
					outline: none;
					padding: 0 24px;
					box-sizing: border-box;
					// margin-top: 10px;
					color: #555555;
				}
			}

			.login_btn {
				width: 240px;
				line-height: 56px;
				background: $theme_color;
				box-shadow: 0px 5px 10px rgba(110, 166, 152, 0.6);
				border-radius: 28px;
				text-align: center;
				font-size: 24px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin: 32px auto 0 auto;
				cursor: pointer;
			}

			.login_remeber {
				margin-top: 20px;
			}
		}

		.police_record {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			bottom: 20px;
			left: 0;

			span {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 22px;
				color: #FFFFFF;
			}

			img {
				width: 18px;
				height: 18px;
				margin-right: 8px;
			}

			.margin_16 {
				margin: 0 16px;
			}

			.police_record_link:hover {
				color: blue;
				cursor: pointer;
			}
		}
	}
</style>
